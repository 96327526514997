// Theme variables //


$primary: #F8D121;
$secondary: #F4B000;
$red: rgba(255,0,0,1);
$google-red: rgba(219,68,55,1);
$yellow: #F7CA00;
$accent: rgba(255,122,0,1);
$blue: rgba(14,39,89,1);
$dark-blue: #040B1A;
$backgroundColor: rgba(243,244,247,1);
$light-grey: #fafafa;
$lightest-sheer: rgba(0,0,0,0.05);


$lh-wider: 1.5625em;


$button-border-radius: 16px;
$card-border-radius: 20px;

$primary-border: 1px solid rgba(14,39,89,0.2);
$secondary-border: 1px solid rgba(255,255,255,0.1);
$grey-sheer: rgba(51,51,51,0.05);
$grey-sheer-light: rgba(51,51,51,0.03);
$blue-sheer: rgba(47, 60, 163, 0.05);

$primary-shadow: 5px 5px 15px 5px rgba(51,51,51,0.03);
$dark: #333333;


$merriweather: 'Merriweather Sans', sans-serif;

//transitions

$transition-all: all 0.2s ease-in-out;
$transition-all-slow:  all 0.5s ease-in-out;

//colors


$light-blue:  #46cbf8;

$green: #17590e;
$dark-green: #102f0b;
$light-green: #30ea18;
$grey: #757575;
$dark-grey: #3b3b3b;

$white: #ffffff;
$black: #000000;
$primary-bg-color: #947777;
$secondary-bg-color: #4c7571;
$primary-text-color: #111010;
$secondary-text-color: #ffffff;
$sheer: rgba(255,255,255,0.5);
$main-text-color: $black;

//borders
$input-border: 1px solid $blue;
$input-border-active: 2px solid $dark-blue;
$table-border: 1px solid $dark-blue;
$input-color: $black;

// Bootstrap variables //
$nav-link-color: $black;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 993px,
        xl: 1280px) !default;

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1230px
) !default;

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
                (
                        0: 0,
                        'tn': ($spacer * .25),
                        1: ($spacer * .5),
                        12: ($spacer * 0.6),
                        2: ($spacer * .75),
                        3: $spacer,
                        25: ($spacer * 1.25),
                        4: ($spacer * 1.5),
                        35: ($spacer * 1.75),
                        5: ($spacer * 2),
                        6: ($spacer * 2.5),
                        7: ($spacer * 3)

                ),
                $spacers
);

//colors
$colors: (
        "grey-sheer-light": $grey-sheer-light,
        "lightest-sheer": $lightest-sheer,
        "primary": $primary,
        "secondary": $secondary,
        "blue": $blue,
        "red": $red,
        "accent": $accent,
        "blue-sheer": $blue-sheer,
        "grey-sheer": $grey-sheer,
        "dark-blue": $dark-blue,
        "light-blue": $light-blue,
        "yellow": $yellow,
        "green": $green,
        "dark-green": $dark-green,
        "light-green": $light-green,
        "grey": $grey,
        "dark-grey": $dark-grey,
        "light-grey": $light-grey,
        "white": $white,
        "black": $black,
        "primary-bg-color": $primary-bg-color,
        "secondary-bg-color": $secondary-bg-color,
        "primary-text-color": $primary-text-color,
        "secondary-text-color": $secondary-text-color,
        "sheer": $sheer,
        "main-text-color": $main-text-color,
);

@each $key, $val in $colors {
  .text-#{$key} {
    color: $val;
  }
  .bg-#{$key} {
    background-color: $val;
  }
}
